import React from 'react'
import classNames from 'classnames'

import css from './styles.module.scss'
import Title from '../Title'
import Wysiwyg from '../Wysiwyg'
import CircleImage from '../CircleImage'

// component
// ==========================================================================================
const Hero = ({
  heroHeading,
  heroBody,
  heroImage1Sharp,
  heroImage2Sharp,
  isOnDarkBackground,
  hasAnchors,
}) => {
  return (
    <section
      className={classNames(css.wrap, { [css.onDark]: isOnDarkBackground })}
    >
      <div className="row">
        <div
          className={classNames('col-sm-12 col-md-10 offset-lg-1', {
            'col-lg-7': heroImage1Sharp,
            'col-lg-9': !heroImage1Sharp,
          })}
        >
          <div className={css.textWrap}>
            <div className={css.titleWrap}>
              <Title tag="h1" content={heroHeading} />
            </div>
            <Wysiwyg
              content={heroBody}
              hasAnchors={hasAnchors}
              className="biggest"
            />
          </div>
          {/* there is no design for only having this image */}
          {heroImage1Sharp && heroImage2Sharp && (
            <div className={css.image2Positioner}>
              <div className={css.image2PositionerInner}>
                <div className={css.image2Wrap}>
                  <CircleImage
                    image={heroImage2Sharp}
                    position="bottomRight"
                    className={css.image2}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {heroImage1Sharp && (
          <div className="col-sm-8 offset-sm-4 col-md-5 offset-md-7 col-lg-4 offset-lg-0">
            <div
              className={classNames(css.image1Wrap, {
                [css.hasImage2]: heroImage2Sharp,
              })}
            >
              <CircleImage
                image={heroImage1Sharp}
                position="topLeft"
                className={css.image1}
                d={0.05}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default Hero
