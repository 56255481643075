import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { get } from 'lodash'

import Hero from '../components/Hero'
import Slices from '../components/Slices'

export const query = graphql`
  query CareerQuery($lang: String) {
    prismic {
      data: allCareers(lang: $lang) {
        edges {
          node {
            meta: _meta {
              id
              type
              uid
              lang
              alternateLanguages {
                uid
                lang
                type
              }
            }
            metaTitle: meta_title
            metaDescription: meta_description
            metaImage: meta_image
            heroHeading: hero_heading
            heroBody: hero_body
            hero_image1
            heroImage1Sharp: hero_image1Sharp {
              childImageSharp {
                fluid(maxWidth: 850) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            hero_image2
            heroImage2Sharp: hero_image2Sharp {
              childImageSharp {
                fluid(maxWidth: 850) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            slices {
              ... on PRISMIC_CareerSlicesInfo_blocks {
                type
                primary {
                  heading
                }
                fields {
                  block
                }
              }
            }
          }
        }
      }
    }
  }
`

const CareerContainer = (props) => {
  const data = get(props, 'data.prismic.data.edges[0].node')
  return (
    <>
      <Hero {...data} />
      <Slices {...data} />
    </>
  )
}

// CareerContainer.propTypes = {
//   data: PropTypes.shape({
//     prismic: PropTypes.shape({
//       data: PropTypes.shape({}).isRequired,
//     }).isRequired,
//   }).isRequired,
// }

export default CareerContainer
